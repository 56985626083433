@import '../../Styles/Mixin.scss';

#Representante{
    #banner{
        > img{
            position: absolute;
            top: 0;
            left: calc(50% - 960px);
            width: 100%;
            max-width: 1920px;
            min-width: 1920px;
        }
        .conteudos{
            margin-top: 200px;
            .texts{
                width: 430px;
                margin-top: 130px;
                margin-left: 60px;
                span{
                    line-height: 120%;
                    margin-bottom: 15px;
                    display: block;
                }
                p{
                    display: block;
                    margin-bottom: 15px;
                }
                img{
                    position: absolute;
                    right: 0;
                    top: -16px;
                }
            }
        }
    }
    #notice{
        margin-top: 50px;
        .box{
            background: #FFFFFF;
            border: 0.5px solid #BADBFE;
            box-sizing: border-box;
            box-shadow: 0px 0px 17px rgba(117, 183, 240, 0.25);
            border-radius: 16px;
            height: 437px;
            img{
                width: 100%;
                border-top-right-radius: 16px;
                border-top-left-radius: 16px;
            }
            .texts{
                padding: 28px 36px;
                display: flex;
                justify-content: baseline;
                align-items: flex-start;
                flex-direction: column;
                span{
                    line-height: 130%;
                    position: relative;
                    &::after{
                        content: "";
                        position: absolute;
                        width: 72px;
                        height: 6px;
                        left: 0px;
                        bottom: -25px;
                        background: #FFFE06;
                        border-radius: 8px;
                    }
                }
                p{
                    margin-top: 45px;
                }
            }
        }
    }
    #video{
        margin-top: 80px;
        .video-box{
            width: 692px;
            height: 389px;
            box-shadow: 48px 34px 0 0 #FFFE06;
        }
        .texts{
            margin-top: 50px;
            > span{
                line-height: 150%;
                display: block;
                margin-bottom: 15px;
            }
            p{
                font-weight: 500;
                display: block;
                margin-bottom: 30px;
                line-height: 150%;
                span{
                    font-weight: 700;
                }
            }
            a{
                font-weight: 500;
            }
            a:hover{
                color: #00309E;
            }
        }
    }
    #icons{
        margin-top: 100px;
        .box{
            height: 360px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    #contato{
        margin-top: 55px;
        margin-bottom: 55px;
        .box{
            padding: 25px 23px;
            border-radius: 16px;
            .texts{
                flex-direction: column;
                display: flex;
                align-items: center;
                justify-content: center;
                > span{
                    line-height: 130%;
                    display: block;
                    margin-bottom: 30px;
                }
            }
            form{
                padding-left: 60px;
                input{
                    width: 100%;
                    border: none;
                    background: #FFFFFF;
                    border: 1px solid #C5CED7;
                    box-sizing: border-box;
                    border-radius: 8px;
                    height: 40px;
                    padding-left: 10px;
                    margin-bottom: 10px;
                }
                textarea{
                    width: 100%;
                    border: none;
                    background: #FFFFFF;
                    border: 1px solid #C5CED7;
                    box-sizing: border-box;
                    border-radius: 8px;
                    height: 172px;
                    padding-left: 10px;
                    resize: none;
                }
                button{
                    background: #75B7F0;
                    border: none;
                    padding: 7px 55px;
                }
            } 
        }
    }
}


@media screen and (max-width: 750px){
    #Representante{
        #banner{
            > img{
                display: none;
            }
        }
        .conteudos{
            .image{
                margin: -50px auto 0 auto;
                width: 100%;
                display: block;
            }
            .texts{
                width: 100% !important;
                margin-left: 0 !important;
                margin-top: 60px !important;
                span{
                    font-size: 60px;
                }
                img{
                    display: none;
                }
                p{
                    font-size: 30px;
                }
            }
        }
    }
    #notice{
        .box{
            height: auto !important;
            margin-bottom: 45px;
        }
    }
    #video{
        .video-box{
            margin-bottom: 45px;
        }
        .texts{
            text-align: center;
            > span{
                font-size: 45px;
            }
            p{
                font-size: 35px;
                margin: 20px 0 40px 0;
            }
            a{
                font-size: 40px;
                padding: 10px 40px;
                img{
                    width: 40px;
                    margin-left: 10px;
                }
            }
        }
    }
    #icons{
        img{
            width: 400px;
            padding:  0 20px;
        }
    }
    #contato{
        .box{
            .texts{
                margin-bottom: 45px;
                text-align: center;
                span{
                    font-size: 50px;
                }
                p{
                    font-size: 35px;
                }
            }
            form{
                padding-left: 0 !important;
                input{
                    height: 70px !important;
                    font-size: 28px;
                }
                textarea{
                    font-size: 28px;
                }
                button{
                    font-size: 30px;
                    img{
                        width: 40px;
                    }
                }
            }
        }
    }
}