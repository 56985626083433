@import '../../Styles/Mixin.scss';

#credenciados{
    background: #F5F5F5;
    .form-dpn{
        display: none;
    }
    #head{
        background: #00309e;
        height: 110px;
        width: 100%;
    }
    #formg{
        background: #00309e;
        padding-bottom: 95px;
        padding-top: 15px;
        position: relative;
        .left-form{
            span.txt-odonto{
                line-height: 139%;
                display: block;
                margin-bottom: 16px;
                margin-top: 30px;
            }
            .carousel{
                margin-bottom: 20px;
                display: block;
                width: 300px;
                margin: 0 auto  25px auto;
            }
            .carousel-vida{
                margin-bottom: 20px;
                display: block;
                width: 380px;
                z-index: 8;
                position: relative;
            }
            .inf-saude{
                margin-left: 90px;
            }
            .text{
                position: relative;
                span.txt{
                    line-height: 139%;
                    display: block;
                    margin-bottom: 25px;
                }
                p{
                    line-height: 150%; 
                }
                div.breve{
                    width: 105px;
                    height: 105px;
                    border-radius: 100px;
                    position: absolute;
                    right: 125px;
                    span{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;    
                        text-align: center;                }
                }
                div.breve-pet{
                    width: 105px;
                    height: 105px;
                    border-radius: 100px;
                    position: absolute;
                    right: 255px;
                    span{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;    
                        text-align: center;                }
                }
            }
            .BrainhubCarousel__arrows{
                background-color: transparent;
                border: none;
                outline: none;
                span{
                    transform: translate(-50%, -50%) rotate(0);
                    border: none;
                }
            }
            .BrainhubCarousel__arrowRight{
                right: -17px;
                top: 30%;
                 span{
                    background-image: url(../../images/forms/arrow-car-right.svg);
                    width: 31.7px;
                    height: 31.7px;
                  
                }
            }
            .BrainhubCarousel__arrowLeft{
                left: -17px;
                top: 30%;
                span{
                    background-image: url(../../images/forms/arrow-car-left.svg);
                    width: 31.7px;
                    height: 31.7px;
                }
            }
            .image-auto{
                margin-top: 0;
            }
            .image-saude{
                position: absolute;
                bottom: -95px;
                right: -285px;
            }
            .image-dental{
                position: absolute;
                bottom: -95px;
                right: -290px;
            }
            .image-casa{
                position: absolute;
                bottom: -95px;
                right: -45px;
            }
            .image-pet{
                position: absolute;
                bottom: -95px;
                right: 0px;
            }
        }
        .form{
            background: #FFFFFF;
            box-shadow: 0px 0px 9px rgba(186, 219, 254, 0.2);
            border-radius: 16px;
            box-sizing: border-box;
            padding: 35px 10px;
            .infos{
                padding: 0 12px;
                display: flex;
                align-items: center;
                justify-content:flex-start;
                img{
                    margin-bottom: 15px;
                    display: block;
                }
                a{
                    margin-left: 20px;
                }
                p{
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                .box-bl{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 0;
                    border-radius: 8px;
                    margin-bottom: 22px;
                }
            }
            form,
            .form-auto{
                display: flex;
                align-items: center;
                justify-content: space-around;
                flex-direction: row;
                width: 100%;
                flex-wrap: wrap;
                padding: 0 12px;
                input[type=text],
                input[type=email],
                select,
                textarea{
                    background: #FFFFFF;
                    border: 1px solid #C5CED7;
                    box-sizing: border-box;
                    border-radius: 8px;
                    width: 100%;
                    margin-bottom: 15px;
                    padding: 10px 0 10px 10px;
                    color: #8A99A8;
                }
                input::placeholder{
                    color: #8A99A8;
                }
                textarea{
                    resize: none;
                    height: 176px;
                }
                .col-6{
                    input[type=text],
                    input[type=email],
                    select{
                        width: 98%;
                    }
                }
                .ipt-check{
                    text-align: left;
                    input[type=checkbox]{
                        margin-bottom: 25px;
                        margin-top: 10px;
                        float: left;
                        text-align: left;
                    }
                    span{
                        margin-left: 10px;
                        margin-top: 4px;
                        display: inline-block;
                    }
                    button{
                        width: 100%;
                        border: none;
                        color: #fff;
                        padding: 7px 20px;
                        background: #00ACE2;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
                        border-radius: 22px;
                        img{
                            margin-left: 15px;
                        }
                    }
                }
        
            }
            form,.form-gen{
                display: flex;
                align-items: center;
                justify-content: space-around;
                flex-direction: row;
                width: 100%;
                flex-wrap: wrap;
                padding: 0 12px;
                input[type=text],
                input[type=email],
                select,
                textarea{
                    background: #FFFFFF;
                    border: 1px solid #C5CED7;
                    box-sizing: border-box;
                    border-radius: 8px;
                    width: 100%;
                    margin-bottom: 15px;
                    padding: 10px 0 10px 10px;
                    color: #8A99A8;
                }
                input::placeholder{
                    color: #8A99A8;
                }
                textarea{
                    resize: none;
                    height: 176px;
                }
                .col-6{
                    input[type=text],
                    input[type=email],
                    select{
                        width: 98%;
                    }
                }
                .ipt-check{
                    text-align: left;
                    input[type=checkbox]{
                        margin-bottom: 25px;
                        margin-top: 10px;
                        float: left;
                        text-align: left;
                    }
                    span{
                        margin-left: 10px;
                        margin-top: 4px;
                        display: inline-block;
                    }
                    button{
                        width: 100%;
                        border: none;
                        color: #fff;
                        padding: 7px 20px;
                        background: #00ACE2;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
                        border-radius: 22px;
                        img{
                            margin-left: 15px;
                        }
                    }
                }
        
            }
        }
    }
}
.image-celular{
    position: inherit !important;
    display: block;
    margin-bottom: -189px;
    float: right;
}
@media screen and (max-width: 750px){
    .box-form{
        .infos{
            text-align: center;
            img{
                display: block;
                margin: 0 auto;
                width: 450px;
            }
            p{
                display: block;
                margin: 0 auto;
                font-size: 35px;
            }
            .box-bl{
                font-size: 35px !important;
                padding: 24px 0 !important;
            }
        }
    }
    #credenciados #formg .form form input[type=text], 
    #credenciados #formg .form form input[type=email], 
    #credenciados #formg .form form select, 
    #credenciados #formg .form .form-auto input[type=text], 
    #credenciados #formg .form .form-auto input[type=email], 
    #credenciados #formg .form .form-auto select, 
    #credenciados #formg .form .form-auto textarea{
        height: 80px;
        font-size: 28px;
        margin-bottom: 25px;
        padding-left: 15px;
    }
    #credenciados #formg .form form textarea, 
    #credenciados #formg .form .form-auto textarea{
        font-size: 28px;
        margin-bottom: 25px;
        padding-left: 15px;
    }
    #credenciados #formg .form form .ipt-check span, 
    #credenciados #formg .form .form-auto .ipt-check span{
        font-size: 32px;
        margin-top: 19px !important;
        margin-left: 15px !important;
    }
    #credenciados input[type=checkbox]{
        height: 35px;
        width: 35px;
        margin-top: 25px !important;
    }
    #credenciados #formg .form form .ipt-check button,
    #credenciados #formg .form .form-auto .ipt-check button{
        font-size: 40px;
        margin-top: 25px;
        display: block;
        img{
            width: 40px;
        }
    }
    #credenciados{
        .left-form{
            text-align: center;
            > a{
                font-size: 35px;
                padding: 12px 30px;
                display: inline-block;
                margin: 0 auto 45px auto !important;
            }
            .BrainhubCarousel__arrowRight span,
            .BrainhubCarousel__arrowLeft span{
                width: 70.7px !important;
                height: 70.7px !important;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .BrainhubCarousel__arrowRight,
            .BrainhubCarousel__arrowLeft {
                right: 0 !important;
                left: 0 !important;
            }
            .carousel{
                width: 500px !important;
                img{
                    width: 300px;
                }
            }
            .carousel-vida{
                margin: 0 auto !important;
                width: 500px !important;
                img{
                    width: 300px;
                }
            }
            .text{
                .txt{
                    font-size: 50px;
                }
                p{
                    font-size: 34px;
                }
            }
        }
        .image-saude-bottom{
            display: block;
            float: right;
            width: 100%;
            margin-bottom: -94px;  
        }
        
        .image-casa {
            position: inherit !important;
            display: block;
            margin: -167px -28px 12px 0;
            float: right;
        }
        .breve,
        .breve-pet{
            display: none;
        }
        .image-pet-mobile{
            width: 647px;
            margin: -242px -102px -418px 0;
        }
        .image-celular{
            display: none;
        }
    }
}