#modal{
    z-index: 999999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
    overflow: auto;
    transition: .4s;
    visibility: hidden;
    opacity: 0;
}
#modal.active{
    visibility: visible;
    opacity: 1;
}

#modal #modalContent{
    position: absolute;
    left: calc(50% - 365px);
    width: 730px;
    // background-color: #fff;
    // padding: 1rem;
    margin-bottom: 20px;
    transition-delay: .8s;
    transition: .8s;
    top: -100vh;
}
#modal.active #modalContent{
    top: 5%;
}
#modal #modalContent.wd-600{
    width: 600px;
    left: calc(50% - 300px);
}
#modal #modalContent #modalClose{
    position: absolute;
    font-size: 2rem;
    right: 0;
    top: -50px;
    color: #fff;
    cursor: pointer;
}
#modal{
    overflow: auto;
    #modal-contatos{
        padding: 25px 30px;
        > span{
            margin-bottom: 15px;
            display: block;
        }
        .box-md{
            border: #00ACE2 1px solid;
            border-radius: 10px;
            justify-content: center;
            align-items: center;
            display: flex;
            margin-bottom: 15px;
        }
        .box-md:last-child{
            margin-bottom: 0;
        }
        button.fechar{
            position: absolute;
            right: 25px;
            top: 20px;
            background: none;
            color: #fff;
            border: none;
            z-index: 9;
            cursor: pointer;
            width: 20px;
            height: 20px;
            outline: none;
        }
        button.fechar:after{
            content: "";
            background: #00ACE2;
            transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
            height: 2px;
            width: 21px;
            position: absolute;
            right: 0px;
            top: 10px;
        }
        button.fechar:before{
            content: "";
            background: #00ACE2;
            transform: rotate(45deg);
            height: 2px;
            width: 21px;
            position: absolute;
            right: 0px;
            top: 10px;
        }
        .lines-3{
            padding: 10px 0 10px 0;
        }
    }
    #modal-ini{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 16px;
        width: 730px;
        > div{
            width: 100%;
        }
        .buttons{
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin: 25px auto 15px auto;
        }
    }
    button.fechar{
        position: absolute;
        right: 25px;
        top: 20px;
        background: none;
        color: #fff;
        border: none;
        z-index: 9;
        cursor: pointer;
        width: 20px;
        height: 20px;
        outline: none;
    }
    button.fechar:after{
        content: "";
        background: #FFFFFF;
        transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
        height: 2px;
        width: 21px;
        position: absolute;
        right: 0px;
        top: 10px;
    }
    button.fechar:before{
        content: "";
        background: #FFFFFF;
        transform: rotate(45deg);
        height: 2px;
        width: 21px;
        position: absolute;
        right: 0px;
        top: 10px;
    }
}
#loginass{
    background: #00309E;
    text-align: center;
    padding: 50px 0;
    border-radius: 12px;
    button.fechar{
        position: absolute;
        right: 25px;
        top: -30px;
        background: none;
        color: #fff;
        border: none;
        z-index: 9;
        cursor: pointer;
        width: 20px;
        height: 20px;
        outline: none;
    }
    button.fechar:after{
        content: "";
        background: #FFFFFF;
        transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
        height: 2px;
        width: 21px;
        position: absolute;
        right: 0px;
        top: 9px;
    }
    button.fechar:before{
        content: "";
        background: #FFFFFF;
        transform: rotate(45deg);
        height: 2px;
        width: 21px;
        position: absolute;
        right: 0px;
        top: 9px;
    }
    h2{
        color: #fff;
        margin-bottom: 25px;
    }
    input.uk-input{
        width: 80%;
        margin-bottom: 15px;
        padding: 10px 15px;
        border-radius: 10px;
        border: none;
    }
    input.uk-button{
        width: 30%;
        margin-top: 15px;
        padding: 6px 15px;
        border-radius: 10px;
        border: none;
        background: #fff;
    }

}
#modallgpd.active{
    visibility: visible;
    opacity: 1;
}
#modallgpd{
    background: #FFFFFF;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    width: 339px;
    height: 202px;
    position: fixed;
    bottom: 85px;
    left: 45px;
    z-index: 8;
    position: fixed;
    transition: .4s;
    visibility: hidden;
    opacity: 0;
    padding: 38px 24px ;
    img{
        position: absolute;
        top: -35px;
        left: 15px;
    }
    p{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        a{
            color: #00ACE2;
            text-decoration: underline;
            font-size: 14px;
        }
    }
    button{
        background: #00ACE2;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
        border-radius: 22px;
        border: none;
        color: #fff;
        font-size: 18px;
        margin-top: 10px;
        padding: 4px 20px;
        outline: none;
    }
}
@media screen and (max-width: 750px){
    #modallgpd{
        width: 100%;
        height: 300px;
        bottom: 0;
        left: 0;
        p{
            font-size: 27px;
            margin-top: 10px;
            a{
                font-size: 27px;
            }
        }
        button{
            font-size: 32px;
            margin-top: 30px;
        }
        img {
            position: absolute;
            top: -70px;
            left: 28px;
            width: 110px;
        }
    }
}
 