@import '../../Styles/Mixin.scss';

#politicas{
    #menu{
        height: 200px;
        background: #00309E;
        padding-top: 115px;
        .line-menu{
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid rgba(164, 209, 237, 0.2);
            a{
                margin: 20px 10px;
            }
        }
    }
}
@media screen and (max-width: 750px){
    #politicas{
        #menu{
            height: 430px;
            .line-menu{
                flex-direction: column;
                a{
                    margin-top: 30px;
                    margin-bottom: 0px;
                    width: 100%;
                    text-align: center;
                    font-size: 30px;
                }
            }
        }
        .conteudo{
            .titles{
                font-size: 45px;
            }
        }
    }
}