@import '../../Styles/Mixin.scss';

#home{
    #banner{
        margin-bottom: 0px;
        .texto-banner{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            span{
                line-height: 120%;
                margin-top: -120px;
            }
            p{
                line-height: 150%;
                width: 370px;
                margin: 18px 0 18px 0;
            }
            a{
                float: left;
            }
        }
        #bg-banner1{
            position: absolute;
            right: 43.5%;
            top: 0px;
        }
        #bg-banner2{
            position: absolute;
            left: 44%;
            top: 0;
        }
        img.cr-img{
            margin-top: 120px;
            margin-left: -40px;
            margin-bottom: 95px;
        }
    }
}
#produtos{
    .title{
        margin-bottom: 70px;

        #bg-banner1{
            position: absolute;
            left: -153px;
            top: -45px;
        }
        #bg-banner2{
            position: absolute;
            right: -205px;
            top: -45px;
        }
        h2{
            margin-bottom: 15px;
            display: block;
        }
    }
    .box{
        border: 1px solid #73E4EC;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        padding: 25px 0 70px 0;
        margin-bottom: 20px;
        text-decoration: none;
        max-width: 380px;
        border-radius: 10px;
        min-height: 514.31px;
        img.img-pet{
            margin-top: -26px;
        }
        a{
            margin-top: 10px;
            position: absolute;
            bottom: 44px;
        }
        .produto{
            margin-bottom: 15px;
            span{
                font-weight: 600;
            }
        }
        .pdspc{
            margin-bottom: 5px;
            margin-top: 15px;
        }
        .sub-tit{
            line-height: 37px; 
            text-align: center;
        }
        .tb-fipe{
            margin-bottom: 12px;
            margin-top: 8px;
        }
    }
    .super-garante{
        div.titulo{
            background: #FFFE06;
            padding: 5px 85px 5px 23px;
            border-radius: 100px;
            position: relative;
            margin-top: -5px;
            font-weight: 800;
        }
        div.titulo::after{
            content: "";
            position: absolute;
            background: url("../../images/produtos/icon-garante.png");
            width: 77px;
            height: 106px;
            right: 0;
            top: -16px;
        }
        span.asp{
            line-height: 122.9%;
            margin-top: 35px;
            margin-bottom: 35px;
        }
        a.asp-button{
            padding: 7px 41px;
        }
    }
    .box-blue{
        background: #D3E9FF;
        text-align: center;
        > div{
            background: #FFFE06;
            border-radius: 32px;
            width: 211px;
            text-align: center;
            margin-bottom: 30px;
        }
        .tit{
            line-height: 103.4%;
            margin-bottom: 25px;
        }
        > p{
            margin-bottom: 20px
        }
    }
}

#produtoscel{
    .title{
        margin-bottom: 70px;

        #bg-banner1{
            position: absolute;
            left: -153px;
            top: -45px;
        }
        #bg-banner2{
            position: absolute;
            right: -205px;
            top: -45px;
        }
        h2{
            margin-bottom: 15px;
            display: block;
        }
    }
    .box{
        border: 1px solid #73E4EC;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        padding: 25px 0 70px 0;
        margin-bottom: 20px;
        text-decoration: none;
        border-radius: 10px;
        img.img-pet{
            margin-top: -26px;
        }
        a{
            margin-top: 10px;
            position: absolute;
            bottom: 44px;
        }
        .produto{
            margin-bottom: 15px;
            span{
                font-weight: 600;
            }
        }
        .pdspc{
            margin-bottom: 5px;
            margin-top: 15px;
        }
        .sub-tit{
            line-height: 37px; 
            text-align: center;
        }
        .tb-fipe{
            margin-bottom: 12px;
            margin-top: 8px;
        }
    }
    .super-garante{
        div.titulo{
            background: #FFFE06;
            padding: 5px 85px 5px 23px;
            border-radius: 100px;
            position: relative;
            margin-top: -5px;
            font-weight: 800;
        }
        div.titulo::after{
            content: "";
            position: absolute;
            background: url("../../images/produtos/icon-garante.png");
            width: 77px;
            height: 106px;
            right: 0;
            top: -16px;
        }
        span.asp{
            line-height: 122.9%;
            margin-top: 35px;
            margin-bottom: 35px;
        }
        a.asp-button{
            padding: 7px 41px;
        }
    }
    .box-blue{
        background: #D3E9FF;
        text-align: center;
        > div{
            background: #FFFE06;
            border-radius: 32px;
            width: 211px;
            text-align: center;
            margin-bottom: 30px;
        }
        .tit{
            line-height: 103.4%;
            margin-bottom: 25px;
        }
        > p{
            margin-bottom: 20px
        }
    }
}
#solicite{
    margin-top: 180px;
    .left-sl{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        span{
            z-index: 6;
            line-height: 120%;
            margin-top: -160px;
        }
        p{
            position: relative;
            margin-top: 5px;
        }
        a{
            z-index: 6;
            margin-top: 40px;
        }
        .bg-slgrenn{
            position: absolute;
            right: -100px;
            top: -100px;
        }
        .bg-pontos{
            position: absolute;
            left: -411px;
            top: 160px;
        }
    }
    .left-sr{
        position: relative;
        .image{
            z-index: 9;
            position: relative;
            margin-left: -64px;
        }
        .bg-slyellow{
            position: absolute;
            left: 150px;
            top: 15px;
            z-index: 5;
        }
        .bg-green2{
            position: absolute;
            right: -150px;
            bottom: 155px;
        }
    }
}

@media screen and (max-width: 750px){
    #banner{
        .cr-img{
            display: none;
        }
        #bg-banner2,
        #bg-banner1{
            display: none;
        }
        #bg-mobile{
            width: 100%;
            position: absolute;
            top: -226px;
        }
        .texto-banner{
            margin-top: 205px;
            span{
                font-size: 55px;
                margin-top: 100px;
            }
            p{
                font-size: 35px;
                width: 100% !important;
            }
            .bt{
                font-size: 35px;
            }
        }
    }
    #produtos{
        margin-top: 195px;
        .title{
            #bg-banner1{
                display: none;
            }
            #bg-banner2{
                left: -200px;
                top: -120px;
            }
            h2{
                font-size: 60px;
            }
            span{
                font-size: 37px;
                padding: 10px 30px;
                margin-top: 15px;
                display: block;
            }
        }
        .box{
            max-width: 100%;
            padding: 57px 0 220px 0;
            .produto{
                font-size: 50px;
                span{
                    font-size: 50px;
                }
            }
            .font-p-mobal{
                font-size: 55px;
                margin-top: 15px;
                display: block;  
                span{
                    font-size: 40px;
                }
            }
            .font-p-mobal1{
                font-size: 40px;
            }
            a{
                font-size: 40px;
                bottom: 135px;
                padding: 7px 30px;
                .icon{
                    width: 40px;
                }
            }
            .line-bottom{
                position: absolute;
                bottom: 21px;
                width: 100%;
                height: 70px;
                left: 0;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            .sub-tit{
                font-size: 50px;
                line-height: 125%;
            }
        }
        .box.line-box{
            padding: 57px 0 160px 0;
            a{
                bottom: 70px;
            }
        }
        .box-blue{
            padding: 57px 0 150px 0;
            > div{
                width: 400px;
                span{
                    font-size: 45px;
                }
            }
            > span{
                font-size: 55px;
                > span{
                    font-size: 50px;
                }
            }
            p{
                font-size: 35px;
            }
            a {
                font-size: 40px;
                bottom: 62px;
                padding: 7px 30px;
            }
            
        }
    }
}
