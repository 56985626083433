$color_blue: #00309E;
$color_green: #73E4EC;
$color_light_blue: #00ACE2;
$color_yellow: #FFFE06;

/* FONTS */
.font-10{
    font-size: 10px;
}
.font-13{
    font-size: 13px;
}
.font-14{
    font-size: 14px;
}
.font-15{
    font-size: 13px;
}
.font-16{
    font-size: 16px;
}
.font-17{
    font-size: 17px;
}
.font-18{
    font-size: 18px;
}
.font-19{
    font-size: 19px;
}
.font-20{
    font-size: 20px;
}
.font-22{
    font-size: 22px;
}
.font-24{
    font-size: 24px;
}
.font-25{
    font-size: 25px;
}
.font-26{
    font-size: 26px;
}
.font-28{
    font-size: 28px;
}
.font-30{
    font-size: 30px;
}
.font-32{
    font-size: 32px;
}
.font-33{
    font-size: 33px;
}
.font-34{
    font-size: 34px;
}
.font-35{
    font-size: 35px;
}
.font-37{
    font-size: 37px;
}
.font-39{
    font-size: 39px;
}
.font-40{
    font-size: 40px;
}
.font-42{
    font-size: 42px;
}
.font-48{
    font-size: 40px;
}
.font-56{
    font-size: 56px;
}
.font-64{
    font-size: 64px;
}
/* COLORS */
.cl-green{
    color: $color_green;
}
.cl-blue{
    color: $color_blue;
}
.cl-light-blue{
    color: $color_light_blue;
}
.cl-yellow{
    color: $color_yellow;
}
.cl-white{
    color: #fff;
}
.cl-black{
    color: #222222;
}
.cl-gray{
    color: #686868;
}
.cl-end-gray{
    color: #8A99A8;
}

/* BGS */
.bg-green{
    background-color: $color_green;
}
.bg-blue{
    background-color: $color_blue;
}
.bg-light-blue{
    background-color: $color_light_blue;
}
.bg-yellow{
    background-color: $color_yellow;
}
.bg-white{
    background-color: #fff;
}
/*border*/
.border-white{
    border: 2px solid #fff;
}
.border-green{
    border: 2px solid $color_green;
} 
.border-blue{
    border: 2px solid $color_blue;
} 
.border-yellow{
    border: 2px solid $color_yellow;
} 

/* WHEGHT */
.f-wheight-700{
    font-weight: 700;
}
.f-wheight-600{
    font-weight: 600;
}
.f-wheight-500{
    font-weight: 500;
}
.f-wheight-400{
    font-weight: 400;
}
.f-weight-300{
    font-weight: 300;
}
.f-weight-700{
    font-weight: 700;
}
.f-weight-800{
    font-weight: 800;
}
.f-weight-600{
    font-weight: 600;
}
.f-weight-500{
    font-weight: 500;
}
.f-weight-400{
    font-weight: 400;
}
.f-weight-300{
    font-weight: 300;
}
.font-weight-800{
    font-weight: 800;
}
.font-weight-400{
    font-weight: 400;
}
.font-weight-500{
    font-weight: 500;
}