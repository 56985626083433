@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import './Mixin.scss';
@import './hamburguer-menu.scss';

*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}
ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
    li{
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}
.display-none{
    display: none !important;
}
p{
    margin: 0;
}
a{
    color: #000;
    text-decoration: none;
    font-size: 16px;
}

body{
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    overflow-x: hidden;
}
input, button, textarea{
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    font-weight: 400;
}
.desk-none{
    display: none !important;
}
.mb-none{
    display: block;
}
.clear{
    clear: both;
}
.content{
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 1197px;
    margin-right: auto;
    margin-left: auto;
}

.bt{
    padding: 7px 16px;
    border-radius: 50px;
    .icon{
        margin-left: 5px;
    }
}
.bt:hover{
    color: #fff;
    text-decoration: none;
}
.clear{
    clear: both;
}

/* HEADER */
#header{
    overflow: hidden;
    position: absolute;
    top: 0;
    margin: 25px auto 0 auto;
    width: 100%;
    z-index: 9;
    > div{
        height: 100%;
    }
    .row {
        height: 100%;
    }
    .logo{
        display: flex;
        align-items: center;
    }
    .menu{
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > li:last-child{
            a{
                margin-right: 0;
            }
        }
        > li:first-child{
            a{
                margin-left: 40px;
            }
        }
        > li > a{
            margin-right: 25px;
        }
        a:hover{
            color:#fff;
            text-decoration: none;
            position: relative;
        }
        > li > a:hover:after{
            content: "";
            width: 100%;
            height: 4px;
            background: #FFFE06;
            position: absolute;
            top: -4px;
            left: 0;
            border-radius: 8px;

        }
        li.submenu,
        li.submenu2{
            position: relative;
            z-index: 9;
            ul{
                opacity: 0;
                height: 0;
                width: 0;
            }
        }
        li.submenu:hover{
            position: relative;
            ul{
                opacity: 1;
                width: 180px;
                height: 150px;
                position: fixed;
                top: 75px;
                margin-left: -47px;
                background: #fff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 11px;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
                li{
         //           position: relative;
                    z-index: 9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 51px;
                    width: 100%;
                    a{
                        width: 100%;
                        font-size: 16px;
                        color: #686868;
                        position: relative;
                        z-index: 9;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            margin-right: 10px;
                        }
                    }
                }
                li:first-child:before{
                    content: "";
                    position: absolute;
                    width: 121px;
                    height: 2px;
                    left: 15px;
                    bottom: 0px;
                    background: #D6D6D6;
                }
            }
            ul:after{
                content: "";
                position: absolute;
                width: 43.71px;
                height: 43.71px;
                left: 51px;
                top: -4px;
                background: #FFFFFF;
                border-radius: 4px;
                transform: matrix(0.71, 0.68, -0.73, 0.71, 0, 0);
            }
        }
    }
    li.submenu2:hover{
        position: relative;
        ul{
            opacity: 1;
            width: 150px;
            height: 102px;
            position: fixed;
            top: 75px;
            margin-left: -13px;
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 11px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
            li{
                position: relative;
                z-index: 9;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 51px;
                width: 100%;
                a{
                    width: 100%;
                    font-size: 16px;
                    color: #686868;
                    position: relative;
                    z-index: 9;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 0;
                    img{
                        margin-right: 10px;
                    }
                }
            }
            li:first-child:before{
                content: "";
                position: absolute;
                width: 121px;
                height: 2px;
                left: 15px;
                bottom: 0px;
                background: #D6D6D6;
            }
        }
        ul:after{
            content: "";
            position: absolute;
            width: 43.71px;
            height: 43.71px;
            left: 51px;
            top: -4px;
            background: #FFFFFF;
            border-radius: 4px;
            transform: matrix(0.71, 0.68, -0.73, 0.71, 0, 0);
        }
    }
    .menu-right{
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        li:last-child{
            a{
                margin-right: 0;
            }
        }
        a{
            margin-right: 25px;
        }
        a:hover{
            color:#fff;
            text-decoration: none;
            position: relative;
        }
        > li > a.n-bt:hover:after{
            content: "";
            width: 100%;
            height: 4px;
            background: #FFFE06;
            position: absolute;
            top: -4px;
            left: 0;
            border-radius: 8px;

        }
        li.submenu{
            position: relative;
            z-index: 9;
            ul{
                opacity: 0;
                height: 0;
                width: 0;
            }
        }
    }
    .redes{
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 25px;
        img{
            height: 22px;
            margin-right: 13px;
        }
        &::after{
            content: "";
            width: 2000px;
            height: 100%;
            background-color: $color_green;
            position: absolute;
            left: 100%;
        }
    }

}
#contatos{
    .contato-image{
        position: fixed;
        right: 20px;
        bottom: 100px;
        z-index: 7;
        cursor: pointer;
        img{
            width: 125px;
            
        }
    }
}
/* FOOTER */
#footer{
    &.active{
        display: none;
    }
    #footer-1{
        background-color: #F5F5F5;
        padding: 55px 0;
        .row > div{
            display: flex;
        }
        .uls-menus{
            width: 100%;
            ul{
                width: 65%;
                float: left;
                li{
                    padding-left: 14px;
                }
            }
            ul:last-child{
                width: 35%;
                float: left;
            }
        }
        .ft-buttons{
            display: flex;
            text-align: center;
            justify-content: space-around;
            align-items: center;
            a{
                img{
                    margin-right: 5px;
                }
            }
        }
        .ft-line2{
            margin-top: 45px;
            padding-top: 35px;
            border-top: 1px solid #D6D6D6;
            .bt:hover{
                color: #0056b3;
            }
        }
        .ft-redes{
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            a{
                margin-right: 15px;
            }
            a:last-child{
                margin-right: 0;
            }
        }
        .ft-contatos{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            > div{
                width: 100%;
                float: left;
                padding-left: 25px;
            }
        }
        .bd-left{
            border-left: 1px solid #D6D6D6;
            display: flex;
            text-align: center;
            justify-content: space-around;
            align-items: center;
        }
        ul{
            li{
                margin-bottom: 15px;
                a{
                    color: #222222;
                    font-size: 16px;
                    img{
                        width: 22px;
                        margin-right: 8px;
                    }
                }    
                &:last-child{
                    margin-bottom: 0;
                }        
            }
            &.redes:after{
                content: "";
                width: 1px ;
                height: 110px;
                background: #222222;
                position: absolute;
                right: 55px;
                top: -8px;
            }
        }
    }
    #footer-2{
        background-color: #E5E5E5;
        height: 45px;
        margin-bottom: 62px;
        >div{
            height: 100%;
            .row{
                height: 100%;
            }
        }
        p{
            height: 100%;
            display: flex;
            align-items: center;
        }
        .prod{
            float: right;
            a{
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
    #tudo{
        display: block;
        box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
        padding: 10px 0;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #fff;
        z-index: 7;
        .texts{
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        .buttons{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    margin-right: 8px;
                }
            }
            a:first-child{
                margin-right: 25px;
            }
            a.border-blue:hover{
                color: #0056b3;
            }
        }
    }
}

@media screen and (max-width: 750px){
    .menu-mobile{
        top: 0;
        width: 100%;
        right: 0;
    }
}
