@import '../../Styles/Mixin.scss';

#sobre{
    #banner{
        > img{
            position: absolute;
            top: 0;
            left: calc(50% - 960px);
            width: 100%;
            max-width: 1920px;
            min-width: 1920px;
        }
        .conteudos{
            margin-top: 300px;
            .texts{
                margin-top: 240px;
                padding-right: 45px;
                span{
                    line-height: 120%;
                    color: #006CBF;
                    margin-bottom: 45px;
                    display: block;
                }
                p{
                    line-height: 150%;
                }
            }
            .imgmap{
                position: absolute;
                left: -203px;
                top: -107px;
            }
        }
    }
    #nosso-manifesto{
        margin-top: 60px;
        .box-blue{
            padding: 45px 45px 65px 45px;
            border-radius: 16px;
            span{
                font-size: 40px;
                font-weight: bold;
                display: flex;
                align-items: baseline;
                margin-bottom: 35px;
                position: relative;
                img{
                    margin-right: 20px;
                }
                &:after{
                    content: "";
                    background: #FFFE06;
                    width: 72px;
                    height: 6px;
                    border-radius: 8px;
                    position: absolute;
                    left: 0;
                    bottom: -14px;
                }
            }
        }
    }
}

#supergarantesobre{
    margin-top: 60px;
    .title{
        display: flex;
        p{
            margin-left: 25px;
        }
    }
    .title:before{
        content: "";
        width: 72px;
        height: 6px;
        background: #006CBF;
        border-radius: 8px;
        position: absolute;
        left: 6px;
        bottom: -15px;
    }
    .text,
    .image{
        display: flex;
        align-items: center;
    }
    .mg-top-sobre{
        margin-top: 45px;
    }
    a{
        display: inline-block;
        margin-top: 35px;
        margin-bottom: 35px;
    }
    a:hover{
        color: $color_blue;
    }
}


#faq-list{
    .title{
        margin-top: 95px;
        margin-bottom: 30px;
    }
    .faq-row{
        border: none;
        .row-title{
            width: 100%;
            display: block;
            padding: 10px 20px 10px 20px;
            border-radius: 100px;
            border: 2px solid $color_blue;
            color: $color_blue;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 10px;
        }
        .row-content-text{
            color: #68646c;
            padding: 15px 21px 15px 21px;
        }
    }
    p{
        padding: 15px 15px;
    }
} 

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_icon-wrapper__2cftw{
    max-width: none !important;
    max-height: none !important;
}

#garante{
    #banner{
        .title{
            margin-top: 40px;
            margin-bottom: 35px;
            img{
                margin-right: 25px;
            }
            
        }
        a{
            display: inline-block;
            margin-top: 35px;
            margin-bottom: 35px;
        }
        a:hover{
            color: $color_blue;
        }
    }
    #video{
        margin-top: 80px;
        background: #E5F2FF;
        height: 428px;
        iframe{
            width: 898px;
            height: 506px;
            display: block;
            margin: -39px auto 0 auto;
        }
    }
}

@media screen and (max-width: 750px){
    #sobre{
        #banner{
            > img{
                left: -808px;
                display: none;
            }
            .conteudos{
                .imgmap {
                    position: absolute;
                    left: 0;
                    top: -158px;
                }
                .texts{
                    margin-top: 600px;
                    span{
                       font-size: 47px;
                    }
                    p{
                        font-size: 35px;
                    }
                }
            }
        }
    }
    #garante{
        #video{
            iframe {
                width: 650px;
            }
        }
        #banner{
            > img{
                left: -808px;
                display: none;
            }
        }
        .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
            font-size: 20px;
        }
        #faq-list .faq-row .row-content-text {
            font-size: 20px;
        }
    }
}
