@import '../../Styles/Mixin.scss';

#super-auto{
    #banner{
        > img{
            position: absolute;
            top: 0;
            left: calc(50% - 960px);
            width: 100%;
            max-width: 1920px;
            min-width: 1920px;
        }
        .conteudos{
            .img{
                margin-top: 300px;
                margin-bottom: 80px;
            }
        }
    }
    #vantauto{
        margin-top: 85px;
        .image{
            display: flex;
            align-items: center;
                justify-content: center;
                margin-bottom: 35px;
        }
    }
    #nosso-manifesto{
        margin-top: 60px;
        .box-blue{
            padding: 45px 45px 65px 45px;
            border-radius: 16px;
            span{
                font-size: 40px;
                font-weight: bold;
                display: flex;
                align-items: baseline;
                margin-bottom: 35px;
                position: relative;
                img{
                    margin-right: 20px;
                }
                &:after{
                    content: "";
                    background: #FFFE06;
                    width: 72px;
                    height: 6px;
                    border-radius: 8px;
                    position: absolute;
                    left: 0;
                    bottom: -14px;
                }
            }
        }
    }
}

#super-vida{
    #banner{
        > img{
            position: absolute;
            top: 0;
            left: calc(50% - 960px);
            width: 100%;
            max-width: 1920px;
            min-width: 1920px;
        }
        .conteudos{
            .img{
                margin-top: 300px;
                margin-bottom: 80px;
            }
        }
    }
    #vantauto{
        margin-top: 85px;
        .image{
            display: flex;
            align-items: center;
                justify-content: center;
                margin-bottom: 35px;
        }
    }
    #nosso-manifesto{
        margin-top: 60px;
        .box-blue{
            padding: 45px 45px 65px 45px;
            border-radius: 16px;
            span{
                font-size: 40px;
                font-weight: bold;
                display: flex;
                align-items: baseline;
                margin-bottom: 35px;
                position: relative;
                img{
                    margin-right: 20px;
                }
                &:after{
                    content: "";
                    background: #FFFE06;
                    width: 72px;
                    height: 6px;
                    border-radius: 8px;
                    position: absolute;
                    left: 0;
                    bottom: -14px;
                }
            }
        }
    }
    
    #planos {
        margin: 30px 0;
        padding: 0 140px;
        .familia-residencia {
                position: absolute;
                left: -240px;
                bottom: -31px;
                max-height: 687px;
        } 
        .points{
            position: absolute;
            left: -63px;
            bottom: -112px;
        }
        .bg-p-blue1{
            background: #75B7F0;
        }
        .bg-p-blue2{
            background: #2190ce;
        }
        .bg-p-blue3{
            background: #21D2C8;  
        }
        
        .obs-planos {
            position: absolute;
            right: 0;
            bottom: -85px;
        }
    
        .title {
            margin-bottom: 65px;
        }
    
        .bx1{
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            border: 1px solid #75B7F0;
        }
        .bx2{
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            border: 1px solid #00ACE2;
        }
        .bx3{
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            border: 1px solid #00309E;
        }
        .box {
            border-radius: 10px;
            .topo {
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                height: 75px;
                display: flex;
                justify-content: center;
                margin-bottom: 25px;
                padding: 27px;
            }
    
            .list {
                padding: 0 25px;
    
                span {
                    display: flex;
                    font-size: 14px;
                    line-height: 17px;
                    margin-bottom: 15px;
    
                    img {
                        margin-right: 10px;
                    }
                }
            }
    
            .footer {
                text-align: center;
                display: flex;
                align-items: center;
                flex-direction: column;
    
                p {
                    margin: 20px 0 8px 0;
                }
    
                span.valor {
                    margin-bottom: 15px;
                }
    
                a {
                    margin-bottom: 55px;
                }
            }
        }
    }
    #qualdiade{
        margin-top: 75px;
        .cts{
            margin-top: -45px;
        }
        .texts{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            .box{
                text-align: center;
                background: #75B7F0;
                border-radius: 118px;
                width: 542px;
                height: 144px;  
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            > p{
                margin-top: 45px;
            }
        }
    }
    #vantavida{
        margin-top: -45px;
        .title{
            margin-bottom: 75px;
            display: block;
        }
        .line{
            display: flex;
            justify-content: space-around;
            margin-bottom: 65px;
        }
        .line2{
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-bottom: 65px;
            width: 90%;
            margin: 0 auto;
            position: relative;
            .obj1{
                position: absolute;
                left: -160px;
                bottom: 35px;
            }
            .obj2{
                position: absolute;
                right: -160px;
                bottom: 35px;
            }
        }
    }
    #line-spa{
        height: 302px;
        background: #BADBFE;
        border: 0.5px solid #BADBFE;
        box-sizing: border-box;
        box-shadow: 0px 0px 17px rgba(117, 183, 240, 0.25);
        margin-top: 150px;
        .image-super{
            margin-top: -100px;
        }
        .cts{
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: -40px;
        }
    }
    #links{
        margin-top: 115px;
        .al-center{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .box{
            margin-top: 45px;
            background: #FFFFFF;
            border: 1px solid #73E4EC;
            box-sizing: border-box;
            border-radius: 8px;
            width: 99%;
            padding: 20px 12px;
            > div{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                p{
                    margin: 15px 0 25px 0;
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}
#super-celular{
    #banner{
        > img{
            position: absolute;
            top: 0;
            left: calc(50% - 960px);
            width: 100%;
            max-width: 1920px;
            min-width: 1920px;
        }
        .conteudos{
            .img{
                margin-top: 300px;
                margin-bottom: 80px;
            }
        }
    }
    #vantauto{
        margin-top: 85px;
        .image{
            display: flex;
            align-items: center;
                justify-content: center;
                margin-bottom: 35px;
        }
    }
    #nosso-manifesto{
        margin-top: 60px;
        .box-blue{
            padding: 45px 45px 65px 45px;
            border-radius: 16px;
            span{
                font-size: 40px;
                font-weight: bold;
                display: flex;
                align-items: baseline;
                margin-bottom: 35px;
                position: relative;
                img{
                    margin-right: 20px;
                }
                &:after{
                    content: "";
                    background: #FFFE06;
                    width: 72px;
                    height: 6px;
                    border-radius: 8px;
                    position: absolute;
                    left: 0;
                    bottom: -14px;
                }
            }
        }
    }
}


#super-odonto{
    #banner{
        > img{
            position: absolute;
            top: 0;
            left: calc(50% - 960px);
            width: 100%;
            max-width: 1920px;
            min-width: 1920px;
        }
        .conteudos{
            > div{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
            .img{
                margin-top: 300px;
                margin-bottom: 20px;
            }
        }
    }
    .subtitulo {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 120%;
        display: flex;
        align-items: center;
        color: #006CBF;
    }
    #planos {
        margin: 30px 0;
        padding: 0 140px;
        .familia-residencia {
                position: absolute;
                left: -240px;
                bottom: -31px;
                max-height: 687px;
        } 
        .points{
            position: absolute;
            left: -63px;
            bottom: -112px;
        }
        .bg-p-blue1{
            background: #75B7F0;
        }
        .bg-p-blue2{
            background: #2190ce;
        }
        .bg-p-blue3{
            background: #21D2C8;  
        }
        
        .obs-planos {
            position: absolute;
            right: 0;
            bottom: -85px;
        }
    
        .title {
            margin-bottom: 65px;
        }
    
        .bx1{
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            border: 1px solid #75B7F0;
        }
        .bx2{
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            border: 1px solid #00ACE2;
        }
        .bx3{
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            border: 1px solid #00309E;
        }
        .box {
            border-radius: 10px;
            .topo {
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                height: 75px;
                display: flex;
                justify-content: center;
                margin-bottom: 25px;
                padding: 27px;
            }
    
            .list {
                padding: 0 25px;
    
                span {
                    display: flex;
                    font-size: 14px;
                    line-height: 17px;
                    margin-bottom: 15px;
    
                    img {
                        margin-right: 10px;
                    }
                }
            }
    
            .footer {
                text-align: center;
                display: flex;
                align-items: center;
                flex-direction: column;
    
                p {
                    margin: 20px 0 8px 0;
                }
    
                span.valor {
                    margin-bottom: 15px;
                }
    
                a {
                    margin-bottom: 55px;
                }
            }
        }
    }
    #vantavida{
        margin-top: 30px;
        .title{
            margin-bottom: 75px;
            display: block;
        }
        .line{
            display: flex;
            justify-content: space-around;
            margin-bottom: 65px;
        }
        .line2{
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-bottom: 65px;
            width: 90%;
            margin: 0 auto;
            position: relative;
            .obj1{
                position: absolute;
                left: -160px;
                bottom: 35px;
            }
            .obj2{
                position: absolute;
                right: -160px;
                bottom: 35px;
            }
        }
    }
}
#banner-novo{
    .bene{
        width: 100%;
    }
    text-align: center;
    margin-top: 35px;
    &.def-height .line-full{
        height: 161px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: 1px solid #73E4EC;
        .titles-saude{
            line-height: 37px;
            background: #73E4EC;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            font-style: italic;
        }
        .texts{
            display: flex;
            flex-direction: column;
            span{
                width: 100%;
                display: block;
                margin-bottom: 5px;
                img{
                    margin-right: 10px;
                }
            }
        }
        .valor{
            a{
                background: #73E4EC;
            }
        }

    }
    .hover-ajuste:hover{
        color: #000;
    }
    .dp-bl{
        display: block;
    }
    .line-bg{
        background: #D3E9FF;
    }
    .line-full{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 30px 0;
    }
    .titles{
        line-height: 37px;
    }
    .valor{
        a{
            margin-top: 10px;
            display: inline-block;
        }
    }
    .novidade{
        position: absolute;
        width: 211px;
        background: #FFFE06;
        border-radius: 32px;
        top: -15px;
        font-weight: bold;
        font-size: 20px;
    }
}

#line-garante{
    margin-top: 35px;
    .box{
        background: #FFFE06;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 32px 0;
        span{
            margin: 0 15px;
        }
        a:hover{
            color: #fff;
            background: $color_blue;
        }
    }
    .box-2{
        background: #FFFE06;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 07px 0;
        span{
            margin: 0 25px;
        }
        a:hover{
            color: #fff;
            background: $color_blue;
        }
    }
}

@media screen and (max-width: 750px){

    #line-garante{
        .content{
            width: 100%;
        }
        .box {
            background: #FFFE06;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 32px 0;
            flex-direction: column;
            text-align: center;
            span{
                margin: 15px 0;
            }
            a{
                font-size: 27px;
                font-weight: 600;
            }
        }

        .box-2 {
            background: #FFFE06;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 32px 0;
            flex-direction: column;
            text-align: center;
            span{
                margin: 15px 0;
            }
            a{
                font-size: 27px;
                font-weight: 600;
            }
        }
    }

    #banner-novo{
        &.def-height .line-full{
            height: auto;
            .novidade{
                z-index: 2;
            }
            .titles-saude{
                padding: 55px 0 25px 0;
                span{
                    font-size: 55px;
                    margin-bottom: 10px;
                }
            }
            p{
                margin-top: 20px;
            }
            .font-p-mobal1 {
                margin-top: 15px;
            }
            a{
                margin-bottom: 35px;
                img{
                    width: 45px;
                }
            }
        }
        .titles{
            line-height: 37px;
            margin-bottom: 30px;
            margin-top: 30px;
            span{
                font-size: 50px;
                line-height: 50px;
            }
        }
        p{
            font-size: 35px;
        }
        .valor{
            a{
                margin-top: 10px;
                display: inline-block;
                font-size: 40px;
                margin-top: 20px;
                margin-bottom: 10px;
                padding: 12px 35px;
            }
            .font-p-mobal1{
                font-size: 35px;
                margin-top: 35px;
            }
            .font-p-mobal{
                font-size: 50px;
                span{
                    font-size: 40px;
                }
            }
        }
        .novidade{
            position: absolute;
            width: 260px;
            background: #FFFE06;
            border-radius: 32px;
            top: -15px;
            font-weight: bold;
            font-size: 35px;
        }
    }

    #super-auto{
        #banner{
            > img{
                left: -840px;
                display: none;
            }
            .conteudos{
                .img{
                    display: block;
                    margin: 100px auto 0 auto;
                    width: 600px;
                }
                .imgmap {
                    position: absolute;
                    left: 0;
                    top: -158px;
                }
                .texts{
                    margin-top: 600px;
                    span{
                       font-size: 47px;
                    }
                    p{
                        font-size: 35px;
                    }
                }
            }
        }
        #produtos {
            margin-top: 116px;
        }
        #vantauto{ 
            .image{
                img{
                    width: 120px;
                }
            }
        }

    }

    #super-vida{
        #banner{
            > img{
                left: -840px;
                display: none;
            }
            .conteudos{
                .img{
                    display: block;
                    margin: 100px auto 0 auto;
                    width: 600px;
                }
                .imgmap {
                    position: absolute;
                    left: 0;
                    top: -158px;
                }
                .texts{
                    margin-top: 600px;
                    span{
                       font-size: 47px;
                    }
                    p{
                        font-size: 35px;
                    }
                }
            }
        }
        #produtos {
            margin-top: 116px;
        }
        #vantauto{ 
            .image{
                img{
                    width: 120px;
                }
            }
        }
        #planos {
            margin: 100px 0;
            padding: 0;
            .box{
                margin: 0 auto 75px auto;
                display: block;
                .list span {
                    display: flex;
                    font-size: 30px;
                    line-height: 33px;
                    margin-bottom: 27px;
                }
                .footer{
                    p{
                        font-size: 33px;
                    }
                    span.valor{
                        font-size: 45px;
                        span{
                            font-size: 35px;
                        }
                    }
                    a.bt{
                        font-size: 37px;
                        padding: 8px 45px;
                    }
                }
            }
            .last-box{
                margin-bottom: 0;
            }
        }
        #qualdiade{
            .mobile-image{
                width: 674px;
                display: block;
                margin-top: 85px;
            }
            .titulo{
                font-size: 40px;
            }
            .texts{
                .box{
                    width: 100%;
                    margin-top: -50px;
                    height: 180px;
                    border-radius: 30px;
                    > span{
                        font-size: 35px;
                    }
                    > p{
                        font-size: 33px;
                    }
                }
            }
        }
        #vantavida{
            margin-top: 100px;
            span{
                font-size: 45px;
            }
            .image{
                position: relative;
                height: 350px;
                text-align: center;
                img{
                    width: 150px;
                    display: block;
                    margin: 0 auto;
                }
                span{
                    font-size: 30px;
                    text-align: center;
                    bottom: 40px;
                    display: block;
                    margin: 20px auto 0 auto;
                }
                p{
                    display: block;
                    margin: 21px auto 0 auto;
                    font-size: 23px;
                    color: #8A99A8;
                }
            }
        }
        #line-spa{
            height: auto;
            padding-bottom: 50px;
            .image-super{
                display: block;
                margin: 0 0 0 -25px;
                width: 750px;
            }
            .cts{
                margin-top: 15px;
                img{
                    width: 500px;
                }
                a{
                    display: block;
                    width: 80%;
                    text-align: center;
                    margin-top: 45px;
                    font-size: 33px;
                }
            }
        }
    }
}